<template>
  <div class="card-text">
    <div class="card-title text-left">
      <span class="card-title-text"><h3>Infodatek HQ 2.0</h3></span>
    </div>
    <img style="max-width:100%" src="https://construction-camera-stream.infodatek.com/stream.mjpg" alt="Latest Image" />
  </div>
</template>
<script>


export default {
  data() {
    return {
      latestImage: null,
      latestImageUrl: '',
    };
  },
  computed: {
    src: function() {
      return `https://construction-camera.infodatek.com/images/latest.jpg?${new Date().getTime()}`;
    },
  },
};
</script>
