<template>
  <div id="app">
    <!--link rel="stylesheet" href="./assets/css/main.css"-->
    <link
      href="https://stackpath.bootstrapcdn.com/bootswatch/4.1.1/darkly/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-ae362vOLHy2F1EfJtpMbNW0i9pNM1TP2l5O4VGYYiLJKsaejqVWibbP6BSf0UU5i"
      crossorigin="anonymous"
    />
    <!-- <base/> -->
    <div class="container-dashboard">
      <router-view />
    </div>
  </div>
</template>

<script>
// import Base from '@/components/Base.vue'
export default {
  name: 'App',
  components: {
    // Base
  }
}
</script>

<style>
html,
body,
#app {
  max-height: 100vh;
  height: 100vh;
}
.container-dashboard {
  margin: auto;
  padding: 15px;
  height: 100vh;
  padding-bottom: 0;
}

.second-div {
  flex: 1;
}
.fe_container {
  zoom: 1.7 !important;
  height: unset !important;
}
/* .weather .card-body {
  min-height: 402px !important;
}
.news .card-body {
  min-height: 423px !important;
} */

.table {
  font-size: 24px;
  font-weight: 600;
}

.vww__widget {
  margin: auto;
  max-width: 70% !important;
  zoom: 1.2;
}
.vww__widget > .vww__content {
  height: 220px !important;
}
</style>
