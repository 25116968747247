<template>
  <section>
    <b-row v-if="showLatestImage">
      <b-col>
        <b-card
          bg-variant="secondary"
          text-variant="white"
          class="text-center news h-100"

        >
          <div style="max-height: 92vh !important; overflow: hidden;">
            <latest-image v-if="showLatestImage"/>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="!showLatestImage">
      <b-col>
        <b-card
          bg-variant="secondary"
          text-variant="white"
          class="text-center h-100"
        >
          <div class="card-text">
            <p>
              <span class="clock">{{ dateTime | moment('HH:mm') }}</span>
            </p>
            <h2>
              <span class="date">{{ dateTime | moment('dddd D MMMM') }}</span>
            </h2>
          </div>
        </b-card>
      </b-col>
      <b-col cols="7">
        <b-card
          bg-variant="secondary"
          text-variant="white"
          class="text-center news h-100"
        >
          <div  class="card-text" >
            <div class="card-title text-left">
              <span class="card-title-text"><h3>Nieuws</h3></span>
            </div>
            <b-table
              :per-page="perPage"
              :fields="fields"
              :items="newsPosts"
              striped
              hover
            ></b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-show="!showLatestImage" class="second-div">
      <b-col>
        <b-card
          bg-variant="primary"
          text-variant="white"
          class="text-center weather h-100"
        >
          <div class="card-text">
            <div class="card-title text-left">
              <h3>Weersverwachting</h3>
            </div>
            <weather
              :latitude="lat"
              :longitude="long"
              :update-interval="updateWeatherInterval"
              :hide-header="true"
              api-key="6cdf68714492b03d08e4d98a033ab07d"
              language="nl"
              lang="nl"
              title=""
              text-color="#fff"
              bar-color="#303030"
              units="uk"
            >
            </weather>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import VueWeatherWidget from 'vue-weather-widget'

import NewsService from '@/services/NewsService'
import LatestImage from "./LatestImage.vue";

require('moment/locale/nl')
require('vue-moment')
export default {
  components: {
    LatestImage,
    weather: VueWeatherWidget,
    NewsService
  },
  data() {
    return {
      dateTime: null,
      newsPosts: [],
      fields: [{ key: 'title', label: '' }],
      perPage: 7,
      lat: '51.316707',
      long: '3.8404978',
      updateWeatherInterval: 600000,
      showLatestImage: true
    }
  },
  async mounted() {
    this.startNewsFetcher()
    setInterval(this.updateDateTime, 1000)

    // Set showLatestImage to true for 3 minutes, then false for 3 minutes
    setInterval(() => {
      this.showLatestImage = !this.showLatestImage
    }, 180000) // 180000 milliseconds = 3 minutes
  },
  methods: {
    async startNewsFetcher() {
      await this.getNewsPosts();
      // loop every 120 minutes
      setInterval(() => {
        this.getNewsPosts();
      }, 7200000);
    },
    async getNewsPosts() {
      const newsPosts = Object.values((await NewsService.index()).data.articles)
      this.newsPosts = newsPosts.filter((post, index, self) => self.findIndex((t) => t.title === post.title) === index)
    },
    updateDateTime() {

      this.dateTime = new Date()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h2 {
  font-size: 60px;
}
table {
}
section {
  height: 100%;
  display: flex;

  flex-flow: column;
}
thead tr th {
  display: none !important;
  height: 0px !important;
}

.card-title {
  position: relative;
  height: 33px;
  border-bottom: 2px solid #fff;
  margin-top: 4px;
}
.card-title-text {
  position: absolute;
  left: 10px;

  font-size: 18px;
  font-weight: bold;
}
.clock {
  font-size: 170px;
}
.row {
  margin-bottom: 20px;
}
</style>
